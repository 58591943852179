.h-custom {
    height: calc(100% - 73px);
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}

#login-button {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}