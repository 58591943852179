@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,200');

.App .form-control {
  height: 32px !important;
}
.App textarea.form-control {
  line-height: 1;
  padding: 6px 12px;
}
.App .card label {
  margin-top: 10px;
  color: #444;
  font-weight: bold;
}

.App .navbar .navbar-brand {
  text-transform: none;
}

.App a,
.App a:hover,
.App a:focus {
  color: #6bd098;
  font-weight: bold;
}
.App .grid input {
  width: 100%;
  border: none;
  text-align: center;
}
.App .grid td {
  padding: 1px !important;
}
.App .grid td,
.App .grid th {
  width: 50px;
  text-align: center;
  height: 20px;
}
.App .lds-facebook span {
  font-size: 150%;
  position: absolute;
  top: -10px;
  left: -10px;
  color: black;
}
.App .lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.App .lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #16a1d2;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.App .lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.App .lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.App .lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}
.App .text-danger {
  color: #dc3545 !important;
}

.App .btn-primary {
  background-color: #51cbce;
  color: #FFFFFF;
}

.App .btn-danger {
  background-color: #ef8157;
  color: #FFFFFF;
}

.App {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.App .navbar {
  padding: .45rem 0 .45rem 0;
}

.App .navbar p {
  display: inline-block;
  margin: 0;
  margin-right: .5rem;
  line-height: 1.8em;
  font-size: 1em;
  font-weight: 400;
}

.App #navbar-wrapper {
  align-items: center;
}

.App #navbar-wrapper .nav-item {
  margin: 0 .2rem;
}

.App .main-panel > .content {
  padding: 0.75rem 0.75rem;
}

.icon-button,.icon-button-yellow{
  padding:3px;
}
.icon-button img{
  width:18px;
  filter: invert(41%) sepia(1%) saturate(0%) hue-rotate(192deg) brightness(94%) contrast(91%);
}
.icon-button-yellow img{
  width:19px;
  filter: invert(99%) sepia(34%) saturate(7499%) hue-rotate(337deg) brightness(104%) contrast(102%);
}
